import React from 'react';
import KhyatiAvatar from '../assets/images/Avatars/KhyatiAvatar.png';
import ImgOne from '../assets/images/BlogPageOne/ImgOne.png';
import ImgTwo from '../assets/images/BlogPageOne/ImgTwo.png';
import ImgThree from '../assets/images/BlogPageOne/ImgThree.png';

const BlogPageOneInfo = () => {
    return (
        <div className='px-5 xl:px-[120px]'>
            <div className='flex flex-col items-center justify-center pb-6 md:py-12 xl:py-20 gap-4'>
                <div className='text-[#0072DE] text-center font-semibold'>
                    Fintech
                </div>
                <div className='text-[#111827] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.72] text-center'>
                    The Magic of Compounding: Your Silent Wealth Builder
                </div>
                <div className='text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 text-center'>
                    Stay ahead of the curve with our latest blog releases
                </div>
                <div className='flex gap-4'>
                    <div className='flex items-center justify-center'>
                        <img src={KhyatiAvatar} alt='' className='h-10 w-10 object-cover' />
                    </div>
                    <div className='flex flex-col justify-center'>
                        <div className='text-[#111827] font-semibold text-[16px] leading-6'>
                            Khyati Gandhi
                        </div>
                        <div className='text-[#4B5563] font-normal text-[14px] leading-5'>
                            Product Designer
                        </div>
                    </div>
                </div>
                <div className='xl:w-[1024px] xl:h-[550px]'>
                    <img src={ImgOne} alt='' className="w-full h-full object-cover rounded-[8px]" />
                </div>
            </div>
            <div className='flex flex-col  gap-6 md:gap-10 lg:gap-12 text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 xl:px-52'>
                <div className='flex flex-col gap-5'>
                    <p>
                        In the grand tapestry of wealth creation, there's one thread that stands out, shimmering with potential: compounding. It's the silent, unassuming force that transforms modest sums into substantial fortunes over time. While it might seem like a complex financial concept, the essence of compounding is beautifully simple: earning returns on your earnings.
                    </p>
                    <p>
                        Let's break it down. Imagine you invest Rs. 10,000, earning a 10% return annually. At the end of the first year, you'll have Rs. 11,000. In the second year, you'll earn 10% on Rs. 11,000, not just the original Rs. 10,000. This is compounding in action. As years pass, the growth accelerates, and your money starts working harder for you.
                    </p>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        The Power of Time
                    </div>
                    <p>
                        One of the most fascinating aspects of compounding is the role of time. It's often said that time is the most valuable asset. In the realm of investments, this couldn't be truer. The longer your money stays invested, the more potent the magic of compounding becomes. It's like planting a small seed; with patience and care, it grows into a magnificent tree.
                    </p>
                </div>
                <div className='flex justify-center'>
                    <div className='xl:w-[768px] xl:h-[486px]'>
                        <img src={ImgTwo} alt='' className="w-full h-full object-cover rounded-[8px]" />
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    <p>
                        In India, with our cultural emphasis on long-term planning and financial prudence, compounding aligns perfectly with our investment ethos. Whether it's planning for your child's education, retirement, or building a sizable corpus, the power of compounding can be your greatest ally.
                    </p>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Small Steps, Big Leaps
                    </div>
                    <p>
                        You might be thinking, "I don't have lakhs to invest." That's perfectly okay. Compounding isn't about the initial amount; it's about consistency and time. Even small, regular investments can reap significant rewards over the long term. Start with what you can afford, increase contributions as your income grows, and let compounding work its magic.
                    </p>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Overcoming Hurdles
                    </div>
                    <p>
                        Of course, the journey isn't always smooth. Market fluctuations, inflation, and the temptation to withdraw prematurely can hinder the compounding process. Discipline is key. Stay invested through market cycles, regularly review your portfolio, and avoid emotional decisions.
                    </p>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Harnessing the Power
                    </div>
                    <p>
                        To maximize the benefits of compounding, consider these strategies:
                    </p>
                    <div className='px-6'>
                        <ul className='list-disc ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Start early: </span><span className='text-[#4B5563]'>The earlier you begin, the more time your money has to grow.</span></li>
                        </ul>
                        <ul className='list-disc ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Invest regularly: </span><span className='text-[#4B5563]'>Consistent investments, even small ones, amplify the compounding effect.</span></li>
                        </ul>
                        <ul className='list-disc ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Choose wisely: </span><span className='text-[#4B5563]'>Invest in assets with the potential for long-term growth.</span></li>
                        </ul>
                        <ul className='list-disc ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Reinvest dividends: </span><span className='text-[#4B5563]'>Let your earnings work for you by reinvesting dividends.</span></li>
                        </ul>
                        <ul className='list-disc ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Stay patient: </span><span className='text-[#4B5563]'>Avoid withdrawing prematurely, as it breaks the compounding cycle.</span></li>
                        </ul>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <div className='xl:w-[768px] xl:h-[486px]'>
                        <img src={ImgThree} alt='' className="w-full h-full object-cover rounded-[8px]" />
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Conclusion
                    </div>
                    <p>
                        Compounding is not just a financial concept; it's a philosophy. It's about nurturing growth, patience, and discipline. By understanding and harnessing the power of compounding, you can embark on a journey of financial prosperity that spans generations.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BlogPageOneInfo;