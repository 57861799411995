import React from 'react'

const Contact = () => {
    return (
        <div className='px-5 lg:px-[120px]'>
            <div className='flex flex-col items-center justify-center pb-6 md:py-12 xl:py-20 gap-4'>
                <div className='text-[#0072DE] text-center font-semibold'>
                    Contact us
                </div>
                <div className='text-[#111827] text-center font-semibold text-[36px] leading-[44px]'>
                    Get in touch
                </div>
                <div className='text-[#4B5563] text-center font-normal text-[18px] leading-[28px] w-full lg:w-[60%]'>
                    Have questions? Our support team is here to help. Email us and we will get back to you promptly.
                </div>
            </div>
        </div>
    )
}

export default Contact;