import React from 'react';
import KhyatiAvatar from '../assets/images/Avatars/KhyatiAvatar.png';
import ImgOne from '../assets/images/BlogPageThree/ImgOne.png';
import ImgTwo from '../assets/images/BlogPageThree/ImgTwo.png';

const BlogPageThreeInfo = () => {
    return (
        <div className='px-5 xl:px-[120px]'>
            <div className='flex flex-col items-center justify-center pb-6 md:py-12 xl:py-20 gap-4'>
                <div className='text-[#0072DE] text-center font-semibold'>
                    Fintech
                </div>
                <div className='text-[#111827] font-semibold text-[30px] md:text-[40px] leading-9 lg:leading-[52px] -tracking-[0.72] text-center'>
                    Understanding the Time Value of Money: Your Rupees Are Worth More Today - Finance
                </div>
                <div className='text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 text-center'>
                    Stay ahead of the curve with our latest blog releases
                </div>
                <div className='flex gap-4'>
                    <div className='flex items-center justify-center'>
                        <img src={KhyatiAvatar} alt='' className='h-10 w-10 object-cover' />
                    </div>
                    <div className='flex flex-col justify-center'>
                        <div className='text-[#111827] font-semibold text-[16px] leading-6'>
                            Khyati Gandhi
                        </div>
                        <div className='text-[#4B5563] font-normal text-[14px] leading-5'>
                            Product Designer
                        </div>
                    </div>
                </div>
                <div className='xl:w-[1024px] xl:h-[550px]'>
                    <img src={ImgOne} alt='' className="w-full h-full object-cover rounded-[8px]" />
                </div>
            </div>
            <div className='flex flex-col gap-6 md:gap-10 lg:gap-12 text-[#4B5563] font-normal text-[16px] md:text-[18px] leading-6 md:leading-7 xl:px-52'>
                <div className='flex flex-col gap-5'>
                    <p>
                        <div className='text-[#111827] font-medium'>Have you ever been tempted by a lucrative offer that promised big returns in the future?</div> Before you jump in, it's crucial to understand the concept of the Time Value of Money (TVM). In simple terms, your money today is worth more than the same amount in the future.
                    </p>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Why Does Time Affect Money's Value?
                    </div>
                    <div className=''>
                        There are several reasons for this:
                    </div>
                    <div className='px-6'>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Inflation: </span><span className='text-[#4B5563]'>The purchasing power of your money erodes over time due to inflation.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Opportunity Cost: </span><span className='text-[#4B5563]'>Money in hand can be invested to earn returns, making it grow over time.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Uncertainty: </span><span className='text-[#4B5563]'>There's always a risk that future income might not materialize as expected.</span></li>
                        </ul>
                    </div>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Let's Break It Down with an Example
                    </div>
                    <p>
                        Imagine winning a lottery with two options:
                    </p>
                    <div className='px-6'>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Option A: </span><span className='text-[#4B5563]'>Receive ₹10 lakhs now.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Option B: </span><span className='text-[#4B5563]'>Receive ₹10 lakhs in three years.</span></li>
                        </ul>
                    </div>
                    <p>
                        Most people would choose Option A. Why? Because you can invest that ₹10 lakhs today, earn interest, and end up with more than ₹10 lakhs in three years. This is the power of compounding.
                    </p>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Understanding Future Value and Present Value: An Indian Perspective
                    </div>
                    <div className='flex items-center justify-center'>
                        <img src={ImgTwo} alt='' />
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Future Value (FV)
                    </div>
                    <p>
                        Let's say you have ₹10,000 lying around and decide to invest it in a fixed deposit offering an annual interest rate of 8%. You want to know how much your money will grow in 5 years.
                    </p>
                    <p>
                        This is where Future Value comes in.
                    </p>
                    <p className='text-[#111827] font-medium'>
                        Formula:
                    </p>
                    <div className='px-6'>
                        <ul className='list-disc ml:4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>FV = PV * (1 + r)^n</span></li>
                        </ul>
                    </div>
                    <p className='text-[#111827] font-medium'>
                        Where:
                    </p>
                    <div className='px-6'>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>FV = Future Value (what you'll have in the future)</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>PV = Present Value (what you have now, ₹10,000)</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>r = Interest rate (8% or 0.08)</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>n = Number of periods (5 years)</span></li>
                        </ul>
                    </div>
                    <p className='text-[#111827] font-medium'>
                        Calculation:
                    </p>
                    <div className='px-6'>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>FV = 10,000 * (1 + 0.08)^5</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>FV = 10,000 * (1.08)^5</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>FV = ₹14,693.28</span></li>
                        </ul>
                    </div>
                    <p>
                        So, in 5 years, your ₹10,000 will grow to ₹14,693.28 thanks to the power of compounding interest.
                    </p>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Present Value (PV)
                    </div>
                    <p>
                        Now, let's say your grandmother promises to give you ₹20,000 after 3 years. You're excited, but you want to know how much that money is worth today.
                    </p>
                    <p>
                        This is where Present Value comes in.
                    </p>
                    <p className='text-[#111827] font-medium'>
                        Formula:
                    </p>
                    <div className='px-6'>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>PV = FV / (1 + r)^n</span></li>
                        </ul>
                    </div>
                    <p className='text-[#111827] font-medium'>
                        Where:
                    </p>
                    <div className='px-6'>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>PV = Present Value (what the future money is worth today)</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>FV = Future Value (₹20,000)</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>r = Interest rate (let's assume 7% or 0.07)</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>n = Number of periods (3 years)</span></li>
                        </ul>
                    </div>
                    <p className='text-[#111827] font-medium'>
                        Calculation:
                    </p>
                    <div className='px-6'>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>PV = 20,000 / (1 + 0.07)^3</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>PV = 20,000 / (1.07)^3</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#4B5563]'>PV = ₹15,673.51</span></li>
                        </ul>
                    </div>
                    <p>
                        So, that ₹20,000 your grandmother promised in 3 years is actually worth about ₹15,673.51 in today's money.
                    </p>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Making Informed Decisions
                    </div>
                    <p>
                        Understanding TVM helps you in various financial decisions:
                    </p>
                    <div className='px-6'>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Investments: </span><span className='text-[#4B5563]'>Compare returns from different investment options.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Loans: </span><span className='text-[#4B5563]'>Evaluate the true cost of borrowing.</span></li>
                        </ul>
                        <ul className='list-disc ml-4 xl:ml-6'>
                            <li className='text-[#111827]'><span className='text-[#111827] font-semibold leading-8'>Savings: </span><span className='text-[#4B5563]'>Plan for future goals like buying a house or retirement.</span></li>
                        </ul>
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-[#111827] font-semibold text-[18px] md:text-[24px] leading-6 md:leading-8'>
                        Conclusion
                    </div>
                    <p>
                        By grasping the concept of TVM, you can make smarter financial choices and increase your wealth over time.
                    </p>
                    <p>
                        <span className='text-[#111827] font-medium'>Remember: </span>Your money today is a seed that can grow into a tree of financial security.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BlogPageThreeInfo;