import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoHome from '../assets/images/Logos/Small-Logo.svg';

const NavBar = ({ productRef, solutionsRef, text }) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    // const location = useLocation();

    // const handleProductClick = () => {
    //     if (location.pathname !== '/') {
    //         navigate('/?scrollTo=product');
    //     } else {
    //         productRef.current?.scrollIntoView({ behavior: 'smooth' });
    //     }
    // };

    // const handleSolutionsClick = () => {
    //     if (location.pathname !== '/') {
    //         navigate('/?scrollTo=solutions');
    //     } else {
    //         solutionsRef.current?.scrollIntoView({ behavior: 'smooth' });
    //     }
    // };

    return (
        <div>
            <nav className="px-3 md:px-16 lg:px-25 py-1 md:py-3">
                <div className="container mx-auto flex justify-between items-center text-lg">
                    <div className="h-full flex items-center w-fit cursor-pointer" onClick={() => navigate('/')}>
                        <img src={LogoHome} alt='Logo' className='h-auto w-auto max-w-full max-h-full object-contain' />
                    </div>
                    <div className="hidden lg:flex gap-5 xl:gap-7">
                        <div onClick={() => navigate('/product')} className={`px-2 py-1 hover:text-[#0079C0]  rounded cursor-pointer`}>Product</div>
                        <div onClick={() => navigate('/solutions')} className={`px-2 py-1 hover:text-[#0079C0] rounded cursor-pointer`}>Solutions</div>
                        <div onClick={() => navigate('/blogs')} className={`px-2 py-1 hover:text-[#0079C0] rounded cursor-pointer`}>Resources</div>
                        <div onClick={() => navigate('/company')} className={`px-2 py-1 hover:text-[#0079C0] rounded cursor-pointer`}>About Us</div>
                    </div>
                    <div className='flex space-x-4 opacity-0 hover:cursor-default'>
                        <div
                            className='border rounded-lg px-4 py-1'
                            onClick={() => navigate('')}
                        >
                            Sign In
                        </div>
                        <div
                            className='bg-[#0079C0] rounded-lg px-4 py-1 text-white'
                        >
                            Get Started
                        </div>
                    </div>
                    <div className="px-3 lg:hidden">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="focus:outline-none"
                        >
                            {isOpen ? (
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            ) : (
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
                <div className={`lg:hidden transition-all duration-300 ease-in-out ${isOpen ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}>
                    <div onClick={() => navigate('/product')} className="block px-4 py-2 hover:text-[#0079C0] rounded">Product</div>
                    <div onClick={() => navigate('/solutions')} className="block px-4 py-2 hover:text-[#0079C0] rounded">Solutions</div>
                    <div onClick={() => navigate('/blogs')} className="block px-4 py-2 hover:text-[#0079C0] rounded">Resources</div>
                    <div onClick={() => navigate('/company')} className="block px-4 py-2 hover:text-[#0079C0] rounded">About Us</div>
                </div>
            </nav>
        </div>
    )
}

export default NavBar